<template>
  <div class="row books">
    <div class="col-md-12 mt-2">
      <div class="card">
        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <div class="form-group row">
            <span class="col-sm-2">Select Range</span>
            <div class="col-md-8">
              <v-date-picker
                v-model="range"
                mode="date"
                :masks="masks"
                is-range
              >
                <template v-slot="{ inputValue, inputEvents, isDragging }">
                  <div class="rows">
                    <input
                      class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                      :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                      :value="inputValue.start"
                      v-on="inputEvents.start"
                    />

                    <span class="p-2">to</span>

                    <input
                      class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                      :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                      :value="inputValue.end"
                      v-on="inputEvents.end"
                    />
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="col-md-2">
              <button class="btn btn-block btn-primary" @click="actionSearch">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-md-1 mb-1">
              <button
                class="btn btn-block btn-outline-danger"
                @click="actionCreateTablePDF"
              >
                <i class="fa fa-file-pdf-o"></i>
              </button>
            </div>
            <div class="col-md-1">
              <label for="" class="col-sm-auto">Count </label>
              <strong class="col-sm-auto">
                {{ formatPriceBasic(totalRows) }}
              </strong>
            </div>
          </div>

          <table
            class="table table-striped table-bordered table-sm table-responsive-sm"
          >
            <thead>
              <tr>
                <th>No</th>
                <th>item Name</th>
                <th>Price</th>
                <th>Discount</th>
                <th>Final Price</th>
                <th>Free</th>
                <th>Purchase Date</th>
                <th>Payment</th>
                <th>Gateway Fee</th>
                <th>Gateway Cost</th> 
                <th>Balance</th>
              </tr>
            </thead>
            <tbody v-if="!isLoading">
              <template v-for="(item, index) in buffets">
                <tr :key="item.id">
                  <td>{{ ++index }}</td>
                  <td>{{ item.name }}</td>
                  <td class="text-right">
                    {{ formatPrice(item.price, item.currency_code) }}
                  </td>
                  
                  <td>
                    <span :class="[item.is_discount ? activeClass : errorClass]">
                      {{ item.is_discount ? "Ya" : "No"}}
                    </span>
                  </td>
                  <td class="text-right">
                    {{ formatPrice(item.final_price, item.currency_code) }}
                  </td>
                  <td>
                    <span :class="[item.is_free ? activeClass : errorClass]">
                      {{ item.is_free ? "Ya" : "No"}}
                    </span>
                  </td>

                  <td style="width:10%">{{ formatDate(item.created) }}</td>
                  <td>
                    <PaymentGateway :gateway="item.paymentgateway_id" />
                  </td>
                  <td class="text-right"> 
                    {{ FormatFormula(PaymentCost(item.paymentgateway_id))}}
                  </td>
                  <td class="text-right">
                    {{ formatPrice(CountAmountCost(item.final_price,1,item.paymentgateway_id),item.currency_code)}}
                  </td>
                  <td class="text-right" v-if="item.currency_code =='USD'">
                    {{ formatPrice(reversIDR(finalAmount(item.final_price,CountAmountCost(item.final_price,1,item.paymentgateway_id))),"IDR")}}
                  </td>
                  <td v-else class="text-right">
                    {{ formatPrice(finalAmount(item.final_price,CountAmountCost(item.final_price,1,item.paymentgateway_id)),item.currency_code)}}
                  </td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <template>
                <tr>
                  <td colspan="10">
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong> Loading...</strong>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="4" class="text-center">Total</td>
                <td class="text-right">
                  {{ formatPrice(SumPrice((totalAmountD * curs),totalAmount),"IDR")}}
                </td>
                <td colspan="4"></td>
                <td>
                  {{ formatPrice(gatewayCost,"IDR")}}
                </td>
                <td class="text-right" colspan="1">
                  {{ formatPrice(SumPrice(balanceIDR,balanceUSD),"IDR")}}
                </td>
              </tr>
              <tr>
                <td colspan="10" class="text-center">Total Revenue (Balance * {{ revenue && revenue.length > 0 ? revenue[0].default_percentage_amount: ''}} )</td>
                <td class="text-right">
                  <strong>
                   {{ formatPrice(SumRevenue(SumPrice(balanceIDR,balanceUSD),revenue && revenue.length > 0 ? revenue[0].default_percentage_amount:''),"IDR") }}
                  </strong>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
var doc = new jsPDF({
  orientation: "landscape",
  page:'f4'
});
import { mapState, mapActions } from "vuex";
export default {
  name: "BuffetsReport",
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      totalRows: 0,
      Dates: {
        from: moment().format("YYYY,MM,DD"),
        to: moment().format("YYYY,MM,DD"),
      },
      range: {
        start: moment().format("YYYY,MM,DD"),
        end: moment().format("YYYY,MM,DD"),
      },
      masks: {
        input: "YYYY-MM-DD",
      },
      activeClass: 'badge badge-primary',
      errorClass: 'badge badge-danger',
      curs:14200,
      SumAmount:0,
      balanceIDR:0,
      balanceUSD:0,
      inbalance:0,
      gatewayCost:0
    };
  },
  watch: {
    currentPage: function() {
      this.actionSearch();
    },
    curs: function(){
      this.actionCalcBalance()
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.buffets.isLoading,
      isError: (state) => state.buffets.isError,
      buffets: (state) => state.buffets.items,
    }),
  },
  methods: {
    ...mapActions("buffets", ["fetchReport"]),
    actionCalcBalance(){
      this.balanceIDR=0
      this.balanceUSD=0
      this.gatewayCost=0
      this.buffets.forEach(item => {
        if (item.currency_code =='IDR') {
          let idr = this.finalAmount(item.final_price,this.CountAmountCost(item.final_price,1,item.paymentgateway_id))
          let gIDR = this.CountAmountCost(item.final_price,1,item.paymentgateway_id)
          this.balanceIDR += idr
          this.gatewayCost +=gIDR
        } else if(item.currency_code =='USD'){
          let usd = this.finalAmount(item.final_price,this.CountAmountCost(item.final_price,1,item.paymentgateway_id))
          let gUSD = this.CountAmountCost(item.final_price,1,item.paymentgateway_id)
          this.balanceUSD += (usd * this.curs)
          this.gatewayCost += (gUSD * this.curs)
        }
      });
    },
    formatPrice(value, currency) {
      if (value) {
        var formatter = null;
        if (currency == "USD") {
          formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat("en-ID", {
            style: "currency",
            currency: currency,
          });
        }

        return formatter.format(value);
      }

      return "0";
    },
    formatDate(tgl) {
      return new Date(tgl).toLocaleString();
    },
    customLabel({ name }) {
      if (name) {
        return name;
      } else {
        return "Type Here";
      }
    },
    async actionSearch() {
      let payload = {
        from: this.range.start,
        to: this.range.end,
        page: this.currentPage,
        limit: this.perPage,
      };
      try {
        const report = await this.fetchReport(payload);
        if (report) {
          this.$swal({
            toast: "true",
            position: "top-end",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    PaymentCost(id){
      let p = this.payment.find((e) => e.id == id);
      if (p) {
        let formula = p.formula
        let fixedFormula = parseInt(formula.fixed_amount) > 0 ?  parseInt(formula.fixed_amount) :0;
        let value = parseFloat(formula.percentage_amount) > 0 ? parseFloat(formula.percentage_amount): 0
        return {
          fixed_amount: fixedFormula,
          value: value ,
        };
      } else {
        return id;
      }
    },
     CountAmountCost(amount,count,gatewayid){
      let val = this.PaymentCost(gatewayid)
      if (parseFloat(val.value) > 0 && parseInt(val.fixed_amount) > 0) {
        return (amount * val.value) + val.fixed_amount
      } else if (val.value < 1 && val.fixed_amount > 0){
        return count * val.fixed_amount;
      } else if(val.value > 0 && val.fixed_amount < 1){
        return amount * val.value;
      }

    },
    finalAmount(amount, cost) {
      return parseFloat(amount) - parseFloat(cost);
    },
    SumPrice(data,data1){
      return parseFloat(data) + parseFloat(data1)
    },
    SumRevenue(amount,percetage){
      return parseFloat(amount)  * percetage
    },
    reversIDR(amount){
      return amount * this.curs
    },
    FormatFormula (data){
      if (data) {
        if(data.fixed_amount > 0 && data.value > 0){
          return `Rp ${data.value} + ${data.fixed_amount}`;
        } else if(data.fixed_amount > 0 && data.value < 1) {
          return `Rp ${data.fixed_amount}`
        } else {
          return `${data.value}`;
        }
      }
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    actionCreateTablePDF() {
      let datatable = [];
      var totalPagesExp = "{total_pages_count_string}";
      let n = 0;
      this.buffets.forEach((e) => {
        let final_amount = this.formatPrice(e.final_price, e.currency_code);
        datatable.push({
          no: ++n,
          name: e.name,
          price: e.price,
          final_price: final_amount,
          date: new Date(e.created).toLocaleString(),
        });
      });
      doc.setFontSize(18);
      doc.setTextColor(100);
      doc.text("Report Order ", 14, 16);
      doc.autoTable({
        theme: "grid",
        columnStyles: {
          price: { halign: "right" },
          final_price: { halign: "right" },
        },
        body: datatable,
        startY: 20,
        showHead: "firstPage",
        rowPageBreak: "auto",
        columns: [
          { header: "No", dataKey: "no", styles: { halign: "center" } },
          { header: "Name", dataKey: "name", styles: { halign: "left" } },
          { header: "Price", dataKey: "price", styles: { halign: "right" } },
          {
            header: "Final Price",
            dataKey: "final_price",
            styles: { halign: "right" },
          },
          {
            header: "Date",
            dataKey: "date",
            styles: { halign: "right" },
          },
        ],
        didDrawPage: function(data) {
          var str = "Page " + doc.internal.getNumberOfPages();
          if (typeof doc.putTotalPages === "function") {
            str = str + " of " + totalPagesExp;
          }
          doc.setFontSize(10);
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          doc.text(str, data.settings.margin.left, pageHeight - 10);
        },
      });

      // doc.text("Footer Laporan Orders", 14, doc.lastAutoTable.finalY + 10);
      if (typeof doc.putTotalPages === "function") {
        doc.putTotalPages(totalPagesExp);
      }
      console.table(datatable);
      doc.save("buffets" + this.range.start + ".pdf");
    },
  },
};
</script>
<style scoped>
.books {
  font-family:"Avenir Next" ,"effra", Helvetica, Arial, sans-serif;
}
</style>
